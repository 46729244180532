import React from "react";
import ActiveUserChart from "../../components/dashboard/ActiveUserChart";
import ActivetyChart from "../../components/dashboard/ActivetyChart";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import OverViewSell from "../../components/dashboard/OverViewSell";

function createData(
  no,
  name,
  phoneNumber,
  productCount,
  disCountCost,
  deleveryPrice,
  finalPrice,
  governorate,
  adresses,
  date,
  state
) {
  return {
    no,
    name,
    phoneNumber,
    productCount,
    disCountCost,
    deleveryPrice,
    finalPrice,
    governorate,
    adresses,
    date,
    state,
    history: [
      {
        date: "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="right">{row.fat}</TableCell>
        <TableCell align="right">{row.state}</TableCell>
        <TableCell align="right">{row.date}</TableCell>
        <TableCell align="right">{row.adresses}</TableCell>
        <TableCell align="right">{row.governorate}</TableCell>
        <TableCell align="right">{row.finalPrice}</TableCell>
        <TableCell align="right">{row.deleveryPrice}</TableCell>
        <TableCell align="right">{row.disCountCost}</TableCell>
        <TableCell align="right">{row.productCount}</TableCell>
        <TableCell align="right">{row.phoneNumber}</TableCell>
        <TableCell align="right">{row.name}</TableCell>
        <TableCell align="right">{row.no}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Total price ($)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell component="th" scope="row">
                        {historyRow.date}
                      </TableCell>
                      <TableCell>{historyRow.customerId}</TableCell>
                      <TableCell align="right">{historyRow.amount}</TableCell>
                      <TableCell align="right">
                        {Math.round(historyRow.amount * row.price * 100) / 100}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const rows = [
  createData(
    "0",
    "احمد محسن",
    "07703385350",
    5,
    2000,
    3000,
    9000,
    "موصل",
    "القادسية الاولى",
    "2023/11/2",
    "قيد التوصيل"
  ),
];

function MainScreen() {
  return (
    <div className="w-full h-full overflow-auto">
      <div className="flex gap-7 pt-5 justify-center items-center">
        <div className="bg-white h-24 w-72  rounded-lg shadow">
          <div className="p-5 flex flex-col gap-2 text-right">
            <div>مجموع الارباح</div>
            <div className="font-medium text-xl">0 </div>
          </div>
        </div>
        <div className="bg-white h-24 w-72 rounded-lg shadow">
          <div className="">
            {" "}
            <div className="p-5 flex flex-col gap-2 text-right">
              <div>مجموع المنتجات</div>
              <div className="font-medium text-xl">4</div>
            </div>
          </div>
        </div>
        <div className="bg-white h-24 w-72 rounded-lg shadow">
          <div className="">
            {" "}
            <div className="p-5 flex flex-col gap-2 text-right">
              <div>مجموع الحسابات</div>
              <div className="font-medium text-xl">0</div>
            </div>
          </div>
        </div>
        <div className="bg-white h-24 w-72 rounded-lg shadow">
          <div className="">
            {" "}
            <div className="p-5 flex flex-col gap-2 text-right">
              <div>مجموع المبيعات</div>
              <div className="font-medium text-xl">0 </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-7 px-6 flex gap-7 justify-center items-center">
        <div className="h-72 w-[60%] overflow-hidden rounded-lg shadow bg-white">
          <div className="w-full  p-4 text-right">مخطط التحليل</div>
          <ActivetyChart></ActivetyChart>
        </div>
        <div className="h-72 w-[40%] p-4 text-right   rounded-lg shadow bg-white">
          <div>تحليل البيع</div>
          <div className="w-full h-full justify-center items-center flex">
            <OverViewSell></OverViewSell>
          </div>
        </div>
      </div>
      <div className=" pt-7 px-6">
        <div className="w-full h-96 bg-white rounded-lg shadow">
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell align="right">الخيارات</TableCell>
                  <TableCell align="right">الحالة</TableCell>
                  <TableCell align="right">التاريخ</TableCell>
                  <TableCell align="right">العنوان</TableCell>
                  <TableCell align="right">المحافضة</TableCell>
                  <TableCell align="right">السعر الكلي</TableCell>
                  <TableCell align="right">سعر النقل</TableCell>
                  <TableCell align="right">الخصومات</TableCell>
                  <TableCell align="right">عدد المشتريات</TableCell>
                  <TableCell align="right">رقم الهاتف</TableCell>
                  <TableCell align="right">اسم المشتري</TableCell>
                  <TableCell align="right">No.</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <Row key={row.name} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}

export default MainScreen;
