import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";

import { blue, red } from "@mui/material/colors";
import { Button, IconButton } from "@mui/material";
import { useSignOut } from "react-auth-kit";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { useMediaQuery } from "react-responsive";

function Header() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const logOut = () => {
    removeCookie("jwt");
    navigate("/login");
  };

  return (
    <header
      className={` h-14 bg-white w-full justify-between items-center px-4 z-30`}
    >
      <div className="text-right w-full">
        <IconButton>
          <FullscreenIcon className="w-60 h-60"></FullscreenIcon>
        </IconButton>
      </div>
    </header>
  );
}

export default Header;
