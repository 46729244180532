import React from "react";

function OrderForm() {
  return (
    <form
      className={`fixed flex  justify-center left-[50%] top-[50%] transform translate-x-[-50%] translate-y-[-50%]  items-center "w-3/5"
      }   p-5 rounded-xl z-50`}
      onSubmit={handleSubmit} // Step 4: Attach the submit handler
      style={{
        direction: locale === "en" ? "ltr" : "rtl",
      }}
    >
      <div
        className="w-full h-full bg-white p-4 "
        style={{ direction: locale === "en" ? "ltr" : "rtl" }}
      >

        <TextField
          onChange={(event) => {
            setDiagnosis(event.target.value);
          }}
          value={diagnosis}
          id="outlined-multiline-static"
          size="small"
          sx={{
            width: "100%",
            color: "#fff",
          }}
          multiline
          rows={1}
          label={
            <FormattedMessage
              id={"Medical Diagnosis"}
              defaultMessage="Hello, World!"
            />
          }
          // defaultValue="Hello World"
        />

        <div className="w-full">
          <h5>
            {" "}
            <FormattedMessage
              id={"NextVisitDate"}
              defaultMessage="Hello, World!"
            />
          </h5>
          <LocalizationProvider
            size="small"
            className=" w-full"
            dateAdapter={AdapterDayjs}
          >
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              format="DD/MM/YYYY HH:mm"
              onChange={(newValue) => setNextVisit(newValue.$d)}
              className="w-full"
            />
          </LocalizationProvider>
        </div>

        <div className=" text-right flex w-full gap-[74%]">
          <h5>
            <FormattedMessage
              id={"prescription"}
              defaultMessage="Hello, World!"
            />
          </h5>
          <h5>
            {" "}
            <FormattedMessage id={"No.dosage"} defaultMessage="Hello, World!" />
          </h5>
        </div>
        <div className="w-full ">
          <div className="">
            <div className="flex gap-4 justify-center mb-2 items-center">
              <Autocomplete
                freeSolo
                size="small"
                disableListWrap
                disablePortal
                id="combo-box-demo"
                options={props.pharmaceList}
                getOptionLabel={(option) => {
                  return `${option.name} ${
                    option.tradeName ? `(${option.tradeName})` : ""
                  }`;
                }}
                filterOptions={filterOptions}
                sx={{ width: "33%" }}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setValue(null);
                  setBillId("");
                  setInputValue(newInputValue);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.name === value || option.tradeName === value
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <FormattedMessage
                        id={"Drug name"}
                        defaultMessage="Hello, World!"
                      />
                    }
                  />
                )}
              />
              {pharmaceuticalInputs ? (
                <>
                  <TextField
                    id="outlined-required"
                    size="small"
                    value={tradeName}
                    onChange={(event) => {
                      setTradeName(event.target.value);
                    }} // Update the name state
                    sx={{
                      width: "42%",
                      color: "#fff",
                    }}
                    label={
                      <FormattedMessage
                        id={"Trade name"}
                        defaultMessage="Hello, World!"
                      />
                    }
                    // defaultValue="Hello World"
                  />

                  <TextField
                    id="outlined-required"
                    size="small"
                    value={dose}
                    onChange={(event) => {
                      setDose(event.target.value);
                      // handleBillInputChange("dose", event.target.value);
                    }} // Update the name state
                    sx={{
                      width: "42%",
                      color: "#fff",
                    }}
                    label={
                      <FormattedMessage
                        id={"Dosage"}
                        defaultMessage="Hello, World!"
                      />
                    }
                    // defaultValue="Hello World"
                  />
                  {/* First TextField */}

                  <TextField
                    id="outlined-required"
                    size="small"
                    value={doseNumSecend}
                    onChange={(event) => {
                      setDoseNumSecend(event.target.value);
                    }}
                    sx={{
                      width: "10%",
                      color: "#fff",
                    }}
                  />
                  <span>X</span>
                  <TextField
                    id="outlined-required"
                    size="small"
                    value={doseNumFirst}
                    onChange={(event) => {
                      setDoseNumFirst(event.target.value);
                    }}
                    sx={{
                      width: "10%",
                      color: "#fff",
                    }}
                  />
                </>
              ) : (
                ""
              )}
            </div>
            <div className="flex justify-between gap-3">
              {pharmaceuticalInputs ? (
                <>
                  <FormControl className=" w-1/3 bg-whiteh" size="small">
                    <InputLabel id="demo-simple-select-helper-label">
                      <FormattedMessage
                        id={"Take time"}
                        defaultMessage="Hello, World!"
                      />
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={inTakeTime}
                      label={
                        <FormattedMessage
                          id={"Take time"}
                          defaultMessage="Hello, World!"
                        />
                      }
                      onChange={(event) => {
                        setInTakeTime(event.target.value);
                        handleInTakeTimeInputChange(event.target.value);
                      }}
                    >
                      {props.inTakeTimeList.map((inTakeTime, index) => (
                        <MenuItem value={inTakeTime._id}>
                          {inTakeTime.name}
                        </MenuItem>
                      ))}
                      <MenuItem value={"other"}>
                        {" "}
                        <FormattedMessage
                          id={"other"}
                          defaultMessage="Hello, World!"
                        />
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {showInTakeOtherInput ? (
                    <TextField
                      id="outlined-required"
                      size="small"
                      value={inTakeTimeOther}
                      onChange={(event) => {
                        setInTakeTimeOther(event.target.value);
                      }} // Update the name state
                      sx={{
                        width: "33%",
                        color: "#fff",
                      }}
                      label={
                        <FormattedMessage
                          id={"time to take"}
                          defaultMessage="Hello, World!"
                        />
                      }

                      // defaultValue="Hello World"
                    />
                  ) : (
                    ""
                  )}

                  <TextField
                    id="outlined-multiline-static"
                    size="small"
                    sx={{
                      width: "33%",
                      color: "#fff",
                    }}
                    onChange={(event) => {
                      setDescription(event.target.value);
                    }}
                    label={
                      <FormattedMessage
                        id={"Notes"}
                        defaultMessage="Hello, World!"
                      />
                    }
                    // defaultValue="Hello World"
                  />
                </>
              ) : (
                ""
              )}

              <Button
                sx={{ width: "33%" }}
                variant="contained"
                className="w-full"
                color="success"
                onClick={handeAddBill}
              >
                <FormattedMessage
                  id={"Adding medication"}
                  defaultMessage="Hello, World!"
                />
              </Button>
            </div>
          </div>
        </div>
        <BillTable
          midscapeData={props.midscapeData}
          onBillInsideRemove={props.onBillInsideRemove}
          pharmaceList={props.pharmaceListInside}
        ></BillTable>
        {props.userData && props.userData.length > 0 ? (
          <div>
            المريض يعاني حساسية من :{" "}
            <span className=" text-red-700">{props.userData}</span>
          </div>
        ) : (
          ""
        )}
        {props.midscapeData && props.midscapeData.length > 0 ? (
          <p className="text-left text-sm text-red-700">
            {props.midscapeData.map((midscapedata) => midscapedata.text)}
          </p>
        ) : (
          ""
        )}
        <div className="flex gap-6 w-full justify-between">
          <IconButton>
            {/* <PrintRounded color="action"></PrintRounded> */}
          </IconButton>
          <Button
            sx={{ width: "33%" }}
            type="submit"
            variant="contained"
            className="w-full"
            color="success"
          >
            <FormattedMessage
              id={"Add a prescription"}
              defaultMessage="Hello, World!"
            />
          </Button>
          <IconButton onClick={props.onPrinterClick}>
            <PrintRounded color="action"></PrintRounded>
          </IconButton>
        </div>
      </div>
    </form>
  );
}

export default OrderForm;
