import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import SideBarMenu from "../global/SideBarMenu";
import Header from "../global/Header";
import { useMediaQuery } from "react-responsive";

function PrivateRoute(props) {
  const [cookies] = useCookies([]);
  const [userData, setUserData] = useState({});
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 720px)" });

  const isAuthenticated = cookies.jwt;
  console.log(isAuthenticated);
  console.log();
  if (isAuthenticated) {
    // Token is valid, render the Outlet
    return (
      <div className={`${isTabletOrMobile ? "" : "flex "} w-full h-full`}>
        <SideBarMenu currentUser={props.currentUser}></SideBarMenu>
        <div className="w-full h-full">
          <Header></Header>
          <div className="bg-gray-100 w-full h-[92%]">
            <Outlet />
          </div>
        </div>
      </div>
    );
  } else {
    // User is not authenticated, redirect to /login
    return <Navigate to="/login" />;
  }
}

export default PrivateRoute;
