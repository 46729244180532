import {
  Autocomplete,
  Button,
  Chip,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import ItemImage from "../global/ItemImage";
import ProductImages from "./ProductImages";
import ProductBackgroundImages from "./ProductBackgroundImages";
const activeSelect =
  "inline-block cursor-pointer p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500";
const unactiveSelect =
  "inline-block cursor-pointer p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300";
function NewProduct(props) {
  const [activePage, setActivePage] = useState("productInfo");
  const [historyPatient, setHistoryPatient] = useState([]);

  // Define state to store form input data
  const [formData, setFormData] = useState({
    name: "",
    price: "",
    cost: "",
    description: "",
    Length: "",
    width: "",
    height: "",
    color: [],
    category: { name: "", id: "" },
  });
  console.log(formData);
  useEffect(() => {
    if (props.type === "edit") {
      if (props.data.color) {
        const diseasesArray = [];

        props.data.color.forEach((element) => {
          diseasesArray.push(element.name);
          setHistoryPatient(diseasesArray);
        });
      }

      setFormData({
        ...formData,
        name: props.data.name,
        cost: props.data.cost,
        price: props.data.price,
        description: props.data.description,
        Length: props.data.Length,
        category: {
          name: props.data.category ? props.data.category.name : "",
          id: props.data.category ? props.data.category._id : "",
        },

        width: props.data.width,
        height: props.data.height,
        color: props.data.color,
      });
    }
  }, []);

  const [categories, setCategories] = useState([]);
  const handleChange = (event, value) => {
    setFormData({
      ...formData,
      color: value, // Assuming you want them as a comma-separated string
    });
  };
  console.log(formData);
  useEffect(() => {
    console.log(categories);
  }, [categories]);
  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    formData.categories = categories;
    // Call the onFormSubmit function passed as a prop with the formData
    props.onFormSubmit(formData);
  };

  // Handle changes in form fields
  const handleInputChange = async (name, value) => {
    // Update the formData state with the input data
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (file) => {
    handleInputChange("image", file);
    console.log(formData);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="fixed h-[90%] flex flex-col justify-center left-[50%] top-[50%] transform translate-x-[-50%] translate-y-[-50%]  gap-5 items-center w-3/5 bg-white p-5 rounded-xl z-50"
    >
      <div class="">
        <ul class="flex flex-wrap -mb-px">
          <li class="me-2">
            <div
              onClick={() => {
                setActivePage("productInfo");
              }}
              class={
                activePage === "productInfo" ? activeSelect : unactiveSelect
              }
            >
              معلومات المنتج
            </div>
          </li>
          <li class="me-2">
            <div
              onClick={() => {
                setActivePage("productSell");
              }}
              class={
                activePage === "productSell" ? activeSelect : unactiveSelect
              }
              aria-current="page"
            >
              معلومات البيع
            </div>
          </li>
          <li class="me-2">
            <div
              onClick={() => {
                setActivePage("productBackgroundsImages");
              }}
              class={
                activePage === "productBackgroundsImages"
                  ? activeSelect
                  : unactiveSelect
              }
            >
              صور الخلفية
            </div>
          </li>
          <li class="me-2">
            <div
              onClick={() => {
                setActivePage("productImages");
              }}
              class={
                activePage === "productImages" ? activeSelect : unactiveSelect
              }
            >
              صور المنتج
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                setActivePage("productType");
              }}
              class={
                activePage === "productType" ? activeSelect : unactiveSelect
              }
            >
              التصنيفات
            </div>
          </li>
        </ul>
      </div>
      {activePage === "productInfo" ? (
        <div className="h-96 w-full">
          <div className=" text-right w-full">
            <h5>معلومات المنتج</h5>
          </div>
          <div className="flex w-full gap-4">
            <div className="w-full flex flex-col gap-4">
              <Autocomplete
                freeSolo
                size="small"
                disableListWrap
                disablePortal
                value={formData.category}
                id="combo-box-demo"
                options={props.categoryList}
                getOptionLabel={(option) => option.name} // Specify the field to use as the label
                onChange={(event, newValue) => {
                  if (newValue && newValue._id) {
                    // Check if newValue is not null or undefined
                    setFormData((prev) => ({
                      ...prev,
                      category: {
                        ...prev.category,
                        id: newValue._id,
                        name: newValue.name,
                      },
                    }));
                  }
                  console.log(formData);
                }}
                onInputChange={(event, newInputValue) => {
                  setFormData((prev) => ({
                    ...prev,
                    category: {
                      ...prev.category,
                      id: "",
                      name: newInputValue,
                    },
                  }));
                  console.log(formData);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={"الصنف"} />
                )}
              />
              <TextField
                dir="rtl" // Set the direction to RTL
                required
                value={formData.name}
                onChange={(event) =>
                  handleInputChange("name", event.target.value)
                } // Update the name state
                id="outlined-required"
                size="small"
                sx={{
                  width: "100%",
                  direction: "rtl",
                  textAlign: "right",
                  color: "#fff",
                }}
                label="اسم المنتج"
                InputProps={{
                  style: { textAlign: "right" }, // Apply CSS style to right-align placeholder
                }}
              />
              <TextField
                dir="rtl"
                id="outlined-required"
                size="small"
                value={formData.description}
                onChange={(event) =>
                  handleInputChange("description", event.target.value)
                } // Update the name state
                sx={{
                  width: "100%",
                  direction: "rtl",
                  textAlign: "right",
                  color: "#fff",
                }}
                label="الوصف"
                // defaultValue="Hello World"
                InputProps={{
                  style: { textAlign: "right" }, // Apply CSS style to right-align placeholder
                }}
              />
              <TextField
                dir="rtl"
                id="outlined-required"
                size="small"
                value={formData.cost}
                onChange={(event) =>
                  handleInputChange("cost", event.target.value)
                } // Update the name state
                sx={{
                  width: "100%",
                  direction: "rtl",
                  textAlign: "right",
                  color: "#fff",
                }}
                type="number"
                label="التكلفة"
                // defaultValue="Hello World"
                InputProps={{
                  style: { textAlign: "right" }, // Apply CSS style to right-align placeholder
                }}
              />
              <TextField
                dir="rtl"
                id="outlined-required"
                size="small"
                value={formData.Length}
                onChange={(event) =>
                  handleInputChange("Length", event.target.value)
                } // Update the name state
                sx={{
                  width: "100%",
                  direction: "rtl",
                  textAlign: "right",
                  color: "#fff",
                }}
                type="number"
                label="الطول"
                // defaultValue="Hello World"
                InputProps={{
                  style: { textAlign: "right" }, // Apply CSS style to right-align placeholder
                }}
              />
              <TextField
                dir="rtl"
                id="outlined-required"
                size="small"
                value={formData.width}
                onChange={(event) =>
                  handleInputChange("width", event.target.value)
                } // Update the name state
                sx={{
                  width: "100%",
                  direction: "rtl",
                  textAlign: "right",
                  color: "#fff",
                }}
                type="number"
                label="العرض"
                // defaultValue="Hello World"
                InputProps={{
                  style: { textAlign: "right" }, // Apply CSS style to right-align placeholder
                }}
              />
              <TextField
                dir="rtl"
                id="outlined-required"
                size="small"
                value={formData.height}
                onChange={(event) =>
                  handleInputChange("height", event.target.value)
                } // Update the name state
                sx={{
                  width: "100%",
                  direction: "rtl",
                  textAlign: "right",
                  color: "#fff",
                }}
                type="number"
                label="الارتفاع"
                // defaultValue="Hello World"
                InputProps={{
                  style: { textAlign: "right" }, // Apply CSS style to right-align placeholder
                }}
              />
            </div>
          </div>
        </div>
      ) : activePage === "productSell" ? (
        <div className="h-96 overflow-scroll">
          <div className=" text-right w-full">
            <h5>معلومات البيع</h5>
          </div>
          <div className="flex flex-col gap-4">
            {/* <div className="flex w-full justify-center items-center flex-col">
              <p>بيع نوعي</p>
              <FormControlLabel
                sx={{
                  display: "block",
                }}
                control={
                  <Switch
                    checked={formData.packageSet}
                    onChange={(event) => {
                      handleInputChange("packageSet", !formData.packageSet);
                    }}
                    color="primary"
                  />
                }
              />
            </div> */}

            {formData.packageSet ? (
              <div className="">
                <Button onClick={addCategory}>اضافة بيع نوعي</Button>
                <Autocomplete
                  freeSolo
                  size="small"
                  disableListWrap
                  disablePortal
                  id="combo-box-demo"
                  options={props.packageParent}
                  getOptionLabel={(option) => {
                    return `${option.name}`;
                  }}
                  sx={{ width: "100%" }}
                  onChange={(event, newValue) => {
                    props.getNested(newValue);
                    props.changeSelectedParent(newValue._id);
                  }}
                  // inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    // setValue(null);
                    // setBillId("");
                    // setInputValue(newInputValue);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value || option.tradeName === value
                  }
                  renderInput={(params) => (
                    <TextField {...params} label={"التسيم الرئيسي"} />
                  )}
                />
                <div className="flex flex-wrap w-full">
                  {props.packageNested.map((nest, index) => (
                    <div className=" p-4 border-2">
                      {nest.name}
                      <div>
                        <TextField
                          value={
                            props.selectedNested.find(
                              (item) => item.id === nest._id
                            )?.price || ""
                          }
                          size="small"
                          type="number"
                          onChange={(event) => {
                            props.changeNestedPrice(
                              nest._id,
                              event.target.value
                            );
                          }}
                          placeholder="سعر البيع"
                        ></TextField>
                        <div className="flex justify-center items-center">
                          <FormControlLabel
                            sx={{
                              display: "block",
                              margin: 0,
                            }}
                            control={
                              <Switch
                                checked={
                                  props.defaultPackage === nest._id ? 1 : 0
                                }
                                onChange={(event) => {
                                  props.handleDefaultPackage(nest._id, event);
                                }}
                                color="primary"
                              />
                            }
                          />
                          <span className=" opacity-50">الافتراضي</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {categories.map((category, categoryIndex) => (
                  <div key={categoryIndex}>
                    <TextField
                      type="text"
                      placeholder="الاسم النوعي"
                      value={category.main}
                      onChange={(e) =>
                        updateCategoryName(categoryIndex, e.target.value)
                      }
                    />
                    <Button
                      onClick={() =>
                        addSubcategory(categoryIndex, {
                          name: "",
                          cost: 0,
                        })
                      }
                    >
                      اضافة قسم فرعي
                    </Button>
                    <Button onClick={() => deleteCategory(categoryIndex)}>
                      حذف القسم
                    </Button>

                    <ul>
                      {category.subs.map((sub, subIndex) => (
                        <li key={subIndex}>
                          <TextField
                            type="text"
                            placeholder="الاسم الفرع النوعي"
                            value={sub.name}
                            onChange={(e) =>
                              updateSubcategory(
                                categoryIndex,
                                subIndex,
                                e.target.value,
                                sub.cost
                              )
                            }
                          />
                          <TextField
                            type="number"
                            placeholder="سعر الفرع النوعي"
                            value={sub.cost}
                            onChange={(e) =>
                              updateSubcategory(
                                categoryIndex,
                                subIndex,
                                sub.name,
                                e.target.value
                              )
                            }
                          />
                          <Button
                            onClick={() =>
                              deleteSubcategory(categoryIndex, subIndex)
                            }
                          >
                            حذف الفرع
                          </Button>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            ) : (
              <TextField
                dir="rtl"
                id="outlined-required"
                size="small"
                value={formData.price}
                onChange={(event) =>
                  handleInputChange("price", event.target.value)
                } // Update the name state
                sx={{
                  width: "100%",
                  direction: "rtl",
                  textAlign: "right",
                  color: "#fff",
                }}
                type="number"
                label="سعر البيع"
                // defaultValue="Hello World"
                InputProps={{
                  style: { textAlign: "right" }, // Apply CSS style to right-align placeholder
                }}
              />
            )}
            <TextField
              dir="rtl"
              id="outlined-required"
              size="small"
              value={formData.discountDisplay}
              onChange={(event) =>
                handleInputChange("discountDisplay", event.target.value)
              } // Update the name state
              sx={{
                width: "100%",
                direction: "rtl",
                textAlign: "right",
                color: "#fff",
              }}
              type="number"
              label="سعر التخفيض"
              // defaultValue="Hello World"
              InputProps={{
                style: { textAlign: "right" }, // Apply CSS style to right-align placeholder
              }}
            />
            <Autocomplete
              multiple
              sx={{ width: "100%" }}
              id="tags-filled"
              value={historyPatient}
              onChange={(event, newValue) => {
                handleChange(event, newValue);
                setHistoryPatient(newValue);
                console.log(historyPatient);
              }}
              options={props.colorList.map((option) => option.name)}
              // defaultValue={[top100Films[13].title]}
              freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    color="success"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: "100%" }}
                  label={"الالوان"}
                  placeholder="Favorites"
                />
              )}
            />
          </div>
        </div>
      ) : activePage === "productImages" ? (
        <div>
          <ProductImages
            id={props.product._id}
            images={props.product.images}
            refreshProduct={props.refreshCurrentProduct}
          ></ProductImages>
        </div>
      ) : activePage === "productBackgroundsImages" ? (
        <ProductBackgroundImages
          id={props.product._id}
          images={props.product.backgroundImages}
          refreshProduct={props.refreshCurrentProduct}
        ></ProductBackgroundImages>
      ) : (
        ""
      )}
      <Button
        type="submit"
        variant="contained"
        className="w-full"
        color="success"
      >
        {props.type === "edit" ? <p>تعديل</p> : <p>اضافة</p>}
      </Button>
    </form>
  );
}

export default NewProduct;
