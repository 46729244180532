import { Add, Dashboard, Delete, Edit } from "@mui/icons-material";
import { Fab, IconButton } from "@mui/material";
import { blue } from "@mui/material/colors";
import React, { useState } from "react";
import NewProduct from "../../components/product/NewProduct";
import BackGroundShadow from "../../components/global/BackGroundShadow";
import axios from "axios";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";


function ProductScreen() {
  const [newProductForm, setNewProductForm] = useState(false);
  const [currentProduct, setCurrentProduct] = useState();
  const [productList, setProductList] = useState([]);
  const [packageParent, setPackageParent] = useState([]);
  const [packageNested, setPackageNested] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedNested, setSelectedNested] = useState([]);
  const [defaultPackage, setDefaultPackage] = useState("");
  const [editingData, setEditingData] = useState("");
  const [showEditForm, setShowEditForm] = useState(false);
  const [selectedParent, setSelectedParent] = useState([]);
  const [loading, setLoading] = useState(true);
  const currentURL = window.location.origin; // Get the current URL
  const serverAddress = currentURL.replace(/:\d+/, ":5001"); // Replace the port with 5000      // Fetch dashboard data first
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 720px)" });

  useEffect(() => {
    getProductApi();
    getCategoryApi();
    getColorApi();
    getPacakgeApi();
  }, []);
  const getProductApi = () => {
    axios
      .get(`${serverAddress}/product/`)
      .then((response) => {
        setProductList(response.data);
        console.log(response.data);
        console.log(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  };
  const getColorApi = () => {
    axios
      .get(`${serverAddress}/color/`)
      .then((response) => {
        setColorList(response.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  };

  const getCategoryApi = () => {
    axios
      .get(`${serverAddress}/Category/`)
      .then((response) => {
        setCategoryList(response.data);
        console.log(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  };
  const handleFormData = (data) => {
    data.id = currentProduct._id;
    axios
      .post(`${serverAddress}/product/update`, data)
      .then((response) => {
        // Handle the response if needed
        setNewProductForm(false);
        getProductApi();
        setShowEditForm(false);

        console.log("POST request successful:", response.data);
      })
      .catch((error) => {
        // Handle errors if the request fails
        console.error("Error making POST request:", error);
      });
  };

  const onAddHandle = () => {
    setNewProductForm(true);
  };
  const onDeleteHandle = (id) => {
    console.log(id);
    axios
      .delete(`${serverAddress}/product/${id}`)
      .then((response) => {
        getProductApi();
      })
      .catch((error) => {
        // Handle error, e.g., show an error message
        console.error(`Error deleting category with ID ${id}:`, error);
      });

    console.log(`Delete clicked for id ${id}`);
  };

  const refreshCurrentProduct = () => {
    axios
      .get(`${serverAddress}/product/${currentProduct._id}`)
      .then((response) => {
        setCurrentProduct(response.data); // Update the categories state with the fetched data
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  };

  const onEditHandle = (id) => {
    console.log(id);
    axios
      .get(`${serverAddress}/product/${id}`)
      .then((response) => {
        console.log(response.data);
        setEditingData(response.data);
        setCurrentProduct(response.data);
        setShowEditForm(true);
      })
      .catch((error) => {
        // Handle error, e.g., show an error message
        console.error(`Error deleting category with ID ${id}:`, error);
      });
    console.log(`Delete clicked for id ${id}`);
  };

  const getPacakgeApi = () => {
    axios
      .get(`${serverAddress}/packages/parents`)
      .then((response) => {
        setPackageParent(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  };
  const handleAddButtonClick = () => {
    axios
      .post(`${serverAddress}/product/new`, {})
      .then((response) => {
        // Handle the response if needed
        console.log("POST request successful:", response.data.product);
        setCurrentProduct(response.data.product);
        setNewProductForm(true);
      })
      .catch((error) => {
        // Handle errors if the request fails
        console.error("Error making POST request:", error);
      });
  };
  const changeNestedPrice = (id, price) => {
    // Check if the id already exists in selectedNested
    const isIdExists = selectedNested.some((item) => item.id === id);
    if (isIdExists) {
      // If the id exists, update only the price for that item
      const updatedNested = selectedNested.map((item) =>
        item.id === id ? { ...item, price } : item
      );
      // Update the state with the modified array
      setSelectedNested(updatedNested);
    } else {
      // If the id doesn't exist, add a new item to the array
      const newItem = { id, price };
      setSelectedNested((prevSelectedNested) => [
        ...prevSelectedNested,
        newItem,
      ]);
    }
  };
  const changeSelectedParent = (id) => {
    // Check if the id already exists in selectedNested
    const isIdExists = selectedParent.some((item) => item.id === id);
    if (isIdExists) {
      // If the id exists, update only the price for that item
      const updatedNested = selectedParent.map((item) =>
        item.id === id ? { ...item } : item
      );
      // Update the state with the modified array
      setSelectedParent(updatedNested);
    } else {
      // If the id doesn't exist, add a new item to the array
      const newItem = { id };
      setSelectedParent((prevSelectedNested) => [
        ...prevSelectedNested,
        newItem,
      ]);
    }
  };
  const getPrice = (product) => {
    if (product && product.price) {
      return product.price;
    } else if (
      product &&
      product.categories &&
      product.categories[0] &&
      product.categories[0].subs &&
      product.categories[0].subs[0] &&
      product.categories[0].subs[0].price
    ) {
      return product.categories[0].subs[0].price;
    } else {
      return "السعر نوعي";
    }
  };
  useEffect(() => {}, []);

  const handleDefaultPackage = (id) => {
    setDefaultPackage(id);
  };
  useEffect(() => {
    console.log(selectedNested);
  }, [selectedNested]);
  const getNested = (parent) => {
    console.log(parent);
    axios
      .get(`${serverAddress}/packages/nested/${parent._id}`)
      .then((response) => {
        setPackageNested(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  };
  return (
    <div className="h-full overflow-scroll">
      <div className={`${isTabletOrMobile ? "" : "grid grid-cols-4"} gap-4 p-4`}>
        {productList.map((product, index) => (
          <div class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <a href="#">
              <img
                class="p-8 rounded-t-lg object-cover h-96"
                src={serverAddress + product.images}
                alt="product image"
              />
            </a>
            <div class="px-5 pb-5">
              <a href="#">
                <h5 class="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
                  {product.name}
                </h5>
              </a>
              <div class="flex items-center mt-2.5 mb-5">
                {/* <div class="flex items-center space-x-1 rtl:space-x-reverse">
                  <svg
                    class="w-4 h-4 text-yellow-300"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 22 20"
                  >
                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                  </svg>
                  <svg
                    class="w-4 h-4 text-yellow-300"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 22 20"
                  >
                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                  </svg>
                  <svg
                    class="w-4 h-4 text-yellow-300"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 22 20"
                  >
                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                  </svg>
                  <svg
                    class="w-4 h-4 text-yellow-300"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 22 20"
                  >
                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                  </svg>
                  <svg
                    class="w-4 h-4 text-gray-200 dark:text-gray-600"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 22 20"
                  >
                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                  </svg>
                </div> */}
                {/* <span class="bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ms-3">
                  5.0
                </span> */}
              </div>
              <div class="flex items-center justify-between">
                <span class="text-3xl font-bold text-gray-900 dark:text-white">
                  {getPrice(product)}
                </span>
                <IconButton onClick={() => onEditHandle(product._id)}>
                  <Edit ></Edit>
                </IconButton>
                <IconButton onClick={() => onDeleteHandle(product._id)}>
                  <Delete ></Delete>
                </IconButton>
                <IconButton>
                  <Dashboard></Dashboard>
                </IconButton>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className=" fixed z-50 bottom-5 right-6">
        <Fab
          color="primary"
          sx={{ backgroundColor: blue[700], color: "white" }}
          aria-label="add"
          onClick={handleAddButtonClick}
        >
          <Add />
        </Fab>
      </div>
      {newProductForm ? (
        <>
          <NewProduct
            packageParent={packageParent}
            onFormSubmit={handleFormData}
            packageNested={packageNested}
            handleDefaultPackage={handleDefaultPackage}
            selectedNested={selectedNested}
            colorList={colorList}
            changeSelectedParent={changeSelectedParent}
            defaultPackage={defaultPackage}
            refreshCurrentProduct={refreshCurrentProduct}
            categoryList={categoryList}
            product={currentProduct}
            changeNestedPrice={changeNestedPrice}
            getNested={getNested}
          ></NewProduct>
          <BackGroundShadow
            onClick={() => {
              setNewProductForm(false);
              onDeleteHandle(currentProduct._id);
            }}
          ></BackGroundShadow>
        </>
      ) : (
        ""
      )}
      {showEditForm ? (
        <>
          <NewProduct
            type={"edit"}
            data = {editingData}
            packageParent={packageParent}
            colorList={colorList}
            onFormSubmit={handleFormData}
            packageNested={packageNested}
            handleDefaultPackage={handleDefaultPackage}
            selectedNested={selectedNested}
            changeSelectedParent={changeSelectedParent}
            defaultPackage={defaultPackage}
            refreshCurrentProduct={refreshCurrentProduct}
            categoryList={categoryList}
            product={currentProduct}
            changeNestedPrice={changeNestedPrice}
            getNested={getNested}
          ></NewProduct>
          <BackGroundShadow
            onClick={() => {
              setShowEditForm(false);
            }}
          ></BackGroundShadow>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default ProductScreen;
