import { Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import ItemImage from "../global/ItemImage";

function NewCategory(props) {
  // Define state to store form input data
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    image: "",
  });
  useEffect(() => {
    if (props.type === "edit") {
      setFormData({
        ...formData,
        name: props.data.name,
        description: props.data.description,
        image: props.data.image,
      });
    }
  }, []);

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // Call the onFormSubmit function passed as a prop with the formData
    props.onFormSubmit(formData);
  };

  // Handle changes in form fields
  const handleInputChange = async (name, value) => {
    // Update the formData state with the input data
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (file) => {
    handleInputChange("image", file);
    console.log(formData);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="fixed flex flex-col justify-center left-[50%] top-[50%] transform translate-x-[-50%] translate-y-[-50%]  gap-5 items-center w-3/5 bg-white p-5 rounded-xl z-50"
    >
      <div className=" text-right w-full">
        <h5>معلومات الصنف</h5>
      </div>
      <div className="flex w-full gap-4">
        <div className="w-full flex flex-col gap-4">
          <TextField
            dir="rtl" // Set the direction to RTL
            required
            value={formData.name}
            onChange={(event) => handleInputChange("name", event.target.value)} // Update the name state
            id="outlined-required"
            size="small"
            sx={{
              width: "100%",
              direction: "rtl",
              textAlign: "right",
              color: "#fff",
            }}
            label="اسم الصنف"
            InputProps={{
              style: { textAlign: "right" }, // Apply CSS style to right-align placeholder
            }}
          />
          <TextField
            dir="rtl"
            id="outlined-required"
            size="small"
            value={formData.description}
            onChange={(event) =>
              handleInputChange("description", event.target.value)
            } // Update the name state
            sx={{
              width: "100%",
              direction: "rtl",
              textAlign: "right",
              color: "#fff",
            }}
            label="الوصف"
            // defaultValue="Hello World"
            InputProps={{
              style: { textAlign: "right" }, // Apply CSS style to right-align placeholder
            }}
          />
        </div>
        <ItemImage handleFileChange={handleFileChange}></ItemImage>
      </div>
      <Button
        type="submit"
        variant="contained"
        className="w-full"
        color="success"
      >
        {props.type === "edit" ? <p>تعديل</p> : <p>اضافة</p>}
      </Button>
    </form>
  );
}

export default NewCategory;
