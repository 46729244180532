import React from "react";
import {
  Add,
  Delete,
  Edit,
  GridView,
  InsertEmoticon,
  Rtt,
} from "@mui/icons-material";
import { Fab, IconButton, List, TextField } from "@mui/material";
import { blue } from "@mui/material/colors";
import NewCategory from "../../components/category/NewCategory";
import { useState } from "react";
import BackGroundShadow from "../../components/global/BackGroundShadow";
import axios from "axios";
import { useEffect } from "react";
import ColorForm from "../../components/color/ColorForm";
import OrderForm from "../../components/order/OrderForm";

function OrderScreen() {
  const [newCategoryForm, setNewCategoryForm] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [editingData, setEditingData] = useState();
  const [showEditForm, setShowEditForm] = useState(false);
  const [loading, setLoading] = useState(true);

  const currentURL = window.location.origin; // Get the current URL
  const serverAddress = currentURL.replace(/:\d+/, ":5001"); // Replace the port with 5000      // Fetch dashboard data first
  const handleFormData = (data) => {
    console.log(data);
    axios
      .post(`${serverAddress}/color/`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // Handle the response if needed
        getCategoryApi();
        setNewCategoryForm(false);
        console.log("POST request successful:", response.data);
      })
      .catch((error) => {
        // Handle errors if the request fails
        console.error("Error making POST request:", error);
      });
  };

  useEffect(() => {
    getCategoryApi();
  }, []);

  const getCategoryApi = () => {
    axios
      .get(`${serverAddress}/order/`)
      .then((response) => {
        setCategoriesList(response.data);
        console.log(response.data);
        console.log(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  };

  const handleEditFormData = (data) => {
    console.log(data);
    axios
      .put(`${serverAddress}/order/${editingData._id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        getCategoryApi();
        setShowEditForm(false);
        console.log("POST request successful:", response.data);
      })
      .catch((error) => {
        // Handle errors if the request fails
        console.error("Error making POST request:", error);
      });
  };

  const onDeleteHandle = (id) => {
    console.log(id);
    axios
      .delete(`${serverAddress}/order/${id}`)
      .then((response) => {
        getCategoryApi();
      })
      .catch((error) => {
        // Handle error, e.g., show an error message
        console.error(`Error deleting category with ID ${id}:`, error);
      });

    console.log(`Delete clicked for id ${id}`);
  };

  const onEditHandle = (id) => {
    console.log(id);
    axios
      .get(`${serverAddress}/order/${id}`)
      .then((response) => {
        // Handle success, e.g., show a success message or update the categories list
        console.log(response.data);
        setEditingData(response.data);
        setShowEditForm(true);
      })
      .catch((error) => {
        // Handle error, e.g., show an error message
        console.error(`Error deleting category with ID ${id}:`, error);
      });

    console.log(`Delete clicked for id ${id}`);
  };

  const onAddHandle = () => {
    setNewCategoryForm(true);
  };
  return (
    <div className=" w-full h-full p-4 " style={{ direction: "rtl" }}>
      <div className="relative overflow-x-auto h-full shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                رقم الطلب
              </th>
              <th scope="col" className="px-6 py-3">
                كود الطلب{" "}
              </th>
              <th scope="col" className="px-6 py-3">
                اسم الزبون{" "}
              </th>
              <th scope="col" className="px-6 py-3">
                المحافضة{" "}
              </th>
              <th scope="col" className="px-6 py-3">
                سعر الطلب{" "}
              </th>
              <th scope="col" className="px-6 py-3">
                سعر التوصيل{" "}
              </th>
              <th scope="col" className="px-6 py-3">
                السعر الكلي{" "}
              </th>
              <th scope="col" className="px-6 py-3">
                الحالة{" "}
              </th>
              <th scope="col" className="px-6 py-3">
                الخيارات
              </th>
            </tr>
          </thead>
          {loading ? (
            ""
          ) : (
            <tbody>
              {categoriesList.map((order, index) => (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td className="p-4"></td>
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                    {order.name}
                  </td>
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                    {order.productCount}
                  </td>
                  <td className="px-6 py-4">
                    <IconButton>
                      <GridView></GridView>
                    </IconButton>

                    <IconButton onClick={() => onEditHandle(order._id)}>
                      <Edit></Edit>
                    </IconButton>

                    <IconButton onClick={() => onDeleteHandle(order._id)}>
                      <Delete></Delete>
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      <div className=" fixed z-50 bottom-5 right-6">
        <Fab
          color="primary"
          sx={{ backgroundColor: blue[700], color: "white" }}
          aria-label="add"
          onClick={onAddHandle}
        >
          <Add />
        </Fab>
      </div>
      {newCategoryForm ? (
        <>
          <ColorForm onFormSubmit={handleFormData}></ColorForm>
          <BackGroundShadow
            onClick={() => {
              setNewCategoryForm(false);
            }}
          ></BackGroundShadow>
        </>
      ) : (
        ""
      )}
      {showEditForm ? (
        <>
          <OrderForm
            onFormSubmit={handleEditFormData}
            data={editingData}
            type="edit"
          ></OrderForm>
          <BackGroundShadow
            onClick={() => {
              setShowEditForm(false);
            }}
          ></BackGroundShadow>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default OrderScreen;
