import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import arTranslations from "./lang/ar.json"; // Import the Arabic translations
import enTranslations from "./lang/en.json"; // Import the English translations
import { useEffect, useState } from "react";
import Cookies from "js-cookie"; // For cookies

import { useCookies } from "react-cookie";
import axios from "axios";

import Login from "./screens/auth/Login";
import PrivateRoute from "./screens/auth/PrivateRoute";
import MainScreen from "./screens/dashboard/MainScreen";
import CategoryScreen from "./screens/category/CategoryScreen";
import ProductScreen from "./screens/product/ProductScreen";
import OrderScreen from "./screens/order/OrderScreen";
import CostemersScreen from "./screens/costemers/CostemersScreen";
import CompanyScreen from "./screens/company/CompanyScreen";
import DealsScreen from "./screens/deals/DealsScreen";
import SellPackage from "./screens/package/SellPackage";
import ColorListScreen from "./screens/color/ColorListScreen";
import OrderNewScreen from "./screens/order/OrderNewScreen";

function App() {
  const [cookies, setCookies] = useCookies(["jwt"]); // Add "jwt" to the list of cookies you want to use

  const [isAuthenticated, setIsAuthenticated] = useState(!!cookies.jwt); // Initialize isAuthenticated

  useEffect(() => {
    // This effect runs whenever cookies.jwt changes
    const isAuthenticated = !!cookies.jwt;
    // Update the isAuthenticated state when the "jwt" cookie changes
    setIsAuthenticated(isAuthenticated);
  }, [cookies.jwt]);

  const [currentUser, setCurrentUser] = useState(undefined);

  const navigate = useNavigate();
  const [userAvailable, setUserAvailable] = useState(false);
  const currentURL = window.location.origin; // Get the current URL
  const serverAddress = currentURL.replace(/:\d+/, ":5001"); // Replace the port with 5000      // Fetch dashboard data first

  useEffect(() => {
    const verifyUser = async () => {
      const currentURL = window.location.origin; // Get the current URL
      const serverAddress = currentURL.replace(/:\d+/, ":5001"); // Replace the port with 5000      // Fetch dashboard data first
      const { data } = await axios.post(
        `${serverAddress}/users`,
        {},
        {
          withCredentials: true,
        }
      );
      setCurrentUser(data);
    };
    console.log(currentUser);
    verifyUser();
  }, [cookies, navigate]);

  return (
    <Routes>
      <Route exact path="/" element={<PrivateRoute currentUser={currentUser}/>}>
        <Route exact path="/" element={<MainScreen />} />
        <Route exact path="/category" element={<CategoryScreen />} />
        <Route exact path="/product" element={<ProductScreen />} />
        <Route exact path="/order" element={<OrderScreen />} />
        <Route exact path="/company" element={<CompanyScreen />} />
        <Route exact path="/costemers" element={<CostemersScreen />} />
        <Route exact path="/color" element={<ColorListScreen />} />
        <Route exact path="/newOrder" element={<OrderNewScreen />} />
        <Route exact path="/deals" element={<DealsScreen />} />
        <Route exact path="/package" element={<SellPackage />} />
      </Route>
      <Route exact path="/login" element={<Login />}></Route>
    </Routes>
  );
}

export default App;
