import React, { useEffect, useState } from "react";
import axios from "axios";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import PurchasesNewList from "../../components/order/PurchasesNewList";
import PurchasesFotter from "../../components/order/PurchasesFotter";

const iraqProvinces = [
  "البصرة",
  "القادسية",
  "بابل",
  "بغداد",
  "ذي قار",
  "النجف",
  "كربلاء",
  "واسط",
  "ديالى",
  "المثنى",
  "ميسان",
  "الأنبار",
  "نينوى",
  "صلاح الدين",
  "كركوك",
  "السليمانية",
  "أربيل",
  "دهوك",
];

function OrderNewScreen() {
  const currentURL = window.location.origin; // Get the current URL
  const serverAddress = currentURL.replace(/:\d+/, ":5001"); // Replace the port with 5001
  const [products, setProducts] = useState([]);
  const [purchasesInvoice, setPurchasesInvoice] = useState({});
  const [loading, setLoading] = useState(true);

  const [invoiceNum, setInvoiceNum] = useState("");
  const [customerCode, setCustomerCode] = useState("");
  const [customerID, setCustomerID] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [region, setRegion] = useState("");
  const [governorate, setGovernorate] = useState("");

  const handleChange = (event) => {
    setGovernorate(event.target.value);
  };

  async function getproductsApi() {
    try {
      const response = await axios.get(`${serverAddress}/product/`);
      return response.data;
    } catch (error) {
      console.error("Error fetching product data:", error);
      throw error;
    }
  }

  async function Initializ() {
    try {
      const response = await axios.get(`${serverAddress}/purchases/Initializ`);
      return response.data;
    } catch (error) {
      console.error("Error fetching storage data:", error);
      throw error;
    }
  }

  const fetchData = async () => {
    try {
      const initializ = await Initializ();
      const productsData = await getproductsApi();
      setPurchasesInvoice(initializ);
      setProducts(productsData);

      // Set initial state values for inputs
      setInvoiceNum(initializ.invoiceNum || "");
      setCustomerCode(initializ.CustomerCode || "");
      setCustomerID(initializ.CustomerID || "");
      setPhoneNumber(initializ.phoneNumber || "");
      setRegion(initializ.Region || "");
      setGovernorate(initializ.Governorate || "");

      setLoading(false);
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching data:", error);
    }
  };

  const handlePurchaseListValue = (type, value) => {
    axios
      .post(`${serverAddress}/purchases/ubdateCurrent`, {
        type: type,
        value: value,
        id: purchasesInvoice._id,
      })
      .then((response) => {
        setPurchasesInvoice(response.data);
        console.log("Response:", response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleOnAddNewItem = (productId) => {
    axios
      .post(`${serverAddress}/purchases/addproduct`, {
        productId: productId,
        id: purchasesInvoice._id,
      })
      .then((response) => {
        setPurchasesInvoice(response.data);
        console.log("Response:", response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleProductInsideInvoiceChange = (type, productId, value) => {
    axios
      .post(`${serverAddress}/purchases/ProductInsideInvoiceChange`, {
        type: type,
        productId: productId,
        id: purchasesInvoice._id,
        value: value,
      })
      .then((response) => {
        // setPurchasesInvoice(response.data);
        console.log("Response:", response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleProductInsideInvoiceChangeText = (type, productId, value) => {
    console.log(type);
    console.log(value);
    console.log(productId);
    console.log(purchasesInvoice);
    setPurchasesInvoice((prevPurchasesInvoice) => {
      const updatedProducts = prevPurchasesInvoice.product.map((product) => {
        if (product.id._id === productId) {
          return {
            ...product,
            [type]: value,
          };
        }
        return product;
      });

      return {
        ...prevPurchasesInvoice,
        product: updatedProducts,
      };
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onFinishHandle = () => {
    axios
      .post(`${serverAddress}/purchases/finish`, {
        id: purchasesInvoice._id,
      })
      .then((response) => {
        window.location.reload();
        console.log("Response:", response.data);
      })
      .catch((error) => {
        window.location.reload();
        console.error("Error:", error);
      });
  };

  const handleProductRemove = (id) => {
    console.log(id);
    axios
      .post(`${serverAddress}/purchases/removeProductInsideInvoice`, {
        productId: id,
        PurchasesId: purchasesInvoice._id,
      })
      .then((response) => {
        setPurchasesInvoice(response.data);
        console.log("Response:", response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleBlur = (type, value) => {
    handlePurchaseListValue(type, value);
  };

  return (
    <div>
      {!loading ? (
        <div className="flex flex-col">
          <div className="bg-white p-4 shadow my-2 h-[14vh]">
            <div className="w-full flex items-center justify-around gap-4">
              <TextField
                label={"رقم الوصل"}
                size="small"
                variant="outlined"
                value={invoiceNum}
                onChange={(event) => setInvoiceNum(event.target.value)}
                onBlur={() => handleBlur("invoiceNum", invoiceNum)}
                sx={{
                  width: 170,
                  direction: "rtl",
                  textAlign: "right",
                }}
              ></TextField>

              <TextField
                label={"كود العميل"}
                size="small"
                variant="outlined"
                value={customerCode}
                onChange={(event) => setCustomerCode(event.target.value)}
                onBlur={() => handleBlur("CustomerCode", customerCode)}
                sx={{
                  width: 170,
                  direction: "rtl",
                  textAlign: "right",
                }}
              ></TextField>

              <TextField
                label={"معرف الشخص"}
                size="small"
                variant="outlined"
                value={customerID}
                onChange={(event) => setCustomerID(event.target.value)}
                onBlur={() => handleBlur("CustomerID", customerID)}
                sx={{
                  width: 170,
                  direction: "rtl",
                  textAlign: "right",
                }}
              ></TextField>

              <TextField
                label={"رقم الهاتف"}
                size="small"
                variant="outlined"
                value={phoneNumber}
                onChange={(event) => setPhoneNumber(event.target.value)}
                onBlur={() => handleBlur("phoneNumber", phoneNumber)}
                sx={{
                  width: 170,
                  direction: "rtl",
                  textAlign: "right",
                }}
              ></TextField>

              <TextField
                label={"المنطقة"}
                size="small"
                variant="outlined"
                value={region}
                onChange={(event) => setRegion(event.target.value)}
                onBlur={() => handleBlur("Region", region)}
                sx={{
                  width: 170,
                  direction: "rtl",
                  textAlign: "right",
                }}
              ></TextField>

              <Select
                value={governorate}
                onChange={(event) => setGovernorate(event.target.value)}
                onBlur={() => handleBlur("Governorate", governorate)}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  اختر المحافظة
                </MenuItem>
                {iraqProvinces.map((province) => (
                  <MenuItem key={province} value={province}>
                    {province}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <div className="overflow-scroll h-[68vh]">
            <PurchasesNewList
              products={products}
              handleProductInsideInvoiceChangeText={
                handleProductInsideInvoiceChangeText
              }
              handleProductInsideInvoiceChange={
                handleProductInsideInvoiceChange
              }
              handleProductRemove={handleProductRemove}
              purchasesInvoice={purchasesInvoice}
            ></PurchasesNewList>

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={products}
              size="small"
              freeSolo
              onChange={(event, newValue) => {
                if (newValue) {
                  console.log(newValue);
                  handleOnAddNewItem(newValue._id);
                }
              }}
              getOptionLabel={(option) => option.name}
              sx={{
                width: "100%",
                direction: "rtl",
                textAlign: "right",
                color: "#fff",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"اضافة منتج"}
                  variant="outlined"
                  color="warning"
                />
              )}
            ></Autocomplete>
          </div>
          <div className="h-1/3">
            <PurchasesFotter onFinishHandle={onFinishHandle}></PurchasesFotter>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default OrderNewScreen;
