import { Input, TextField } from "@mui/material";
import axios from "axios";
import React, { createRef, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

function Login() {
  const inputRef = createRef();

  useEffect(() => {
    if (inputRef) inputRef.current.dir = "auto";
  }, []);
  const [cookies] = useCookies([]);
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    if (cookies.jwt) {
      navigate("/");
    }
  }, [cookies, navigate]);

  const [values, setValues] = useState({ phoneNumber: "", password: "" });
  const generateError = (error) =>
    toast.error(error, {
      position: "bottom-right",
    });
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const currentURL = window.location.origin; // Get the current URL
      const serverAddress = currentURL.replace(/:\d+/, ":5001"); // Replace the port with 5000      // Fetch dashboard data first

      const { data } = await axios.post(
        `${serverAddress}/users/login`,
        {
          ...values,
        },
        { withCredentials: true }
      );
      if (data) {
        if (data.errors) {
          console.log(data.errors);
          const { email, password } = data.errors;
          if (email) {
            setErrorMsg(email);
            generateError(email);
          } else if (password) {
            setErrorMsg(password);
            generateError(password);
          }
        } else {
          navigate("/");
        }
      }
    } catch (ex) {
      console.log(ex);
      setErrorMsg(ex);
    }
  };
  return (
    <form
      className="w-full h-full flex justify-center items-center bg-[#f3f4f9]"
      onSubmit={(e) => handleSubmit(e)}
    >
      <div className="w-[30rem] h-[30rem] rounded-lg bg-white ">
        <div className="w-full h-40 bg-[#bc353b] rounded-t-lg flex justify-center items-center">
          <div className="text-center">
            <div className=" text-3xl font-bold text-white">تسجيل الدخول</div>
            <div className=" text-white">
              مرحبا , قم بتسجيل الدخول لعرض لوحة التحكم
            </div>
          </div>
        </div>
        <div className="w-full h-80 rounded-b-lg flex justify-center items-center ">
          <div className="flex flex-col w-full gap-4 justify-center items-center">
            <TextField
              inputRef={inputRef}
              id="outlined-required"
              size="small"
              sx={{
                width: "70%",
                direction: "rtl",
                textAlign: "right",
                color: "#fff",
              }}
              label="الايميل"
              name="phoneNumber"
              value={values.phoneNumber}
              onChange={(e) =>
                setValues({ ...values, [e.target.name]: e.target.value })
              }
              InputProps={{
                style: { textAlign: "right" }, // Apply CSS style to right-align placeholder
              }}
            />{" "}
            <TextField
              inputRef={inputRef}
              id="outlined-required"
              size="small"
              sx={{
                width: "70%",
                direction: "rtl",
                textAlign: "right",
                color: "#fff",
              }}
              type="password"
              label="الباسورد"
              name="password"
              value={values.password}
              onChange={(e) =>
                setValues({ ...values, [e.target.name]: e.target.value })
              }
              InputProps={{
                style: { textAlign: "right" }, // Apply CSS style to right-align placeholder
              }}
            />{" "}
            <button type="sumbit" class="mt-5 tracking-wide font-semibold bg-[#bc353b] text-gray-100 w-full py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none">
              <span class="ml-3">تسجيل الدخول</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default Login;
