import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

import { useEffect, useState } from "react";
import Sidebaritems from "../../components/Sidebar/Sidebaritems";
import LanguageSelector from "./lanquageSelector";
import {
  AcUnit,
  AccountTree,
  Add,
  Apartment,
  Archive,
  Assignment,
  Category,
  ColorLens,
  Dashboard,
  Details,
  DisplaySettings,
  Home,
  HomeMax,
  LocalMall,
  LocalTaxi,
  ModeOfTravel,
  Moving,
  Paragliding,
  Person,
  Surfing,
} from "@mui/icons-material";
import logo from "../../logo.png"; // Tell webpack this JS file uses this image
import { useMediaQuery } from "react-responsive";

function SideBarMenu(props) {
  const [collapsedMode, setCollapsedMode] = useState(true);
  const [activeSubmenu, setActiveSubmenu] = useState("main");
  console.log(props.currentUser);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 720)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 720px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

  return (
    <Sidebar
      // backgroundColor="#081F4B"
      collapsed={collapsedMode}
      style={isTabletOrMobile ? { width: "100%" } : { height: "100vh" }}
    >
      <Menu
        className=" bg-[#1d1d1d] h-full"
        closeOnClick
        menuItemStyles={{
          button: ({ level, active, disabled }) => {
            // only apply styles on first level elements of the tree
            if (level === 0)
              return {
                color: disabled ? "#000" : "#bc353b",
                backgroundColor: active ? "#bc353b" : "undefined",
                "&:hover": {
                  backgroundColor: active
                    ? "#bc353b !important"
                    : "#bc353b !important",
                  color: "white !important",
                  display: "flex",
                },
              };
          },
        }}
      >
        <MenuItem
          icon={<MenuOutlinedIcon />}
          onClick={() => {
            setCollapsedMode(!collapsedMode);
          }}
          style={{ textAlign: "center" }}
          className="pt-1 pb-4"
        >
          {/* <img className="w-10" src={logo} alt="Logo" /> */}
        </MenuItem>
        {props.currentUser ? (
          props.currentUser.role === "fullAdmin" ? (
            <>
              <Sidebaritems
                active={activeSubmenu === "main"}
                icon={<Dashboard style={{ fontSize: "28px" }} />}
                title="الرئيسي"
                router="/"
                onClick={() => setActiveSubmenu("main")}
              ></Sidebaritems>
              <Sidebaritems
                title="الاقسام"
                active={activeSubmenu === "category"}
                icon={<Category style={{ fontSize: "28px" }} />}
                router="/category"
                onClick={() => setActiveSubmenu("category")}
              ></Sidebaritems>
              <Sidebaritems
                title="الالوان"
                active={activeSubmenu === "color"}
                icon={<ColorLens style={{ fontSize: "28px" }} />}
                router="/color"
                onClick={() => setActiveSubmenu("color")}
              ></Sidebaritems>
              <Sidebaritems
                title="المنتجات"
                active={activeSubmenu === "product"}
                icon={<Archive style={{ fontSize: "28px" }} />}
                router="/product"
                onClick={() => setActiveSubmenu("product")}
              ></Sidebaritems>
            </>
          ) : (
            ""
          )
        ) : (
          ""
        )}
        <Sidebaritems
          title="اضافة اوردر"
          active={activeSubmenu === "newOrder"}
          icon={<Add style={{ fontSize: "28px" }} />}
          router="/newOrder"
          onClick={() => setActiveSubmenu("newOrder")}
        ></Sidebaritems>

        {/* <Sidebaritems
          title="قائمة المستخدمين"
          active={activeSubmenu === "costemers"}
          icon={<Person style={{ fontSize: "28px" }} />}
          router="/costemers"
          onClick={() => setActiveSubmenu("costemers")}
        ></Sidebaritems> */}
        {/* <Sidebaritems
          title="قائمة العروض"
          active={activeSubmenu === "deals"}
          icon={<Details style={{ fontSize: "28px" }} />}
          router="/deals"
          onClick={() => setActiveSubmenu("deals")}
        ></Sidebaritems> */}
        {/* <Sidebaritems
          title="اقسام العرض"
          active={activeSubmenu === "custemView"}
          icon={<Dashboard style={{ fontSize: "28px" }} />}
          router="/deals"
          onClick={() => setActiveSubmenu("custemView")}
        ></Sidebaritems> */}
        {/* <Sidebaritems
          title="وحدات البيع"
          active={activeSubmenu === "unitType"}
          icon={<AccountTree style={{ fontSize: "28px" }} />}
          router="/package"
          onClick={() => setActiveSubmenu("unitType")}
        ></Sidebaritems> */}

        <Sidebaritems
          title="الطلبات"
          active={activeSubmenu === "order"}
          icon={<LocalMall style={{ fontSize: "28px" }} />}
          router="/order"
          onClick={() => setActiveSubmenu("order")}
        ></Sidebaritems>

        {/* <LanguageSelector onLanguageChange={props.onLanguageChange} /> */}
      </Menu>
    </Sidebar>
  );
}

export default SideBarMenu;
