import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PurchasesInput from "./PurchasesInput";
import { PurchasesAutoComplet } from "./PurchasesAutoComplet";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const storageSelectorData = ["مخزن 1", "مخزن ادوية"];

  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          {/* {row.name} */}
          <a>{row ? (row.id ? row.id.name : "") : ""}</a>
        </TableCell>
        <TableCell align="center">
          <TextField
            size="small"
            onChange={(event) =>
              props.handleProductInsideInvoiceChangeText(
                "quantity",
                row.id._id,
                event.target.value
              )
            }
            onBlur={(event) =>
              props.handleProductInsideInvoiceChange(
                "quantity",
                row.id._id,
                event.target.value
              )
            }
            value={row.quantity}
          ></TextField>
        </TableCell>
        <TableCell align="center">
          <TextField
            size="small"
            onChange={(event) =>
              props.handleProductInsideInvoiceChangeText(
                "purchasesPrice",
                row.id._id,
                event.target.value
              )
            }

            onBlur={(event) =>
              props.handleProductInsideInvoiceChange(
                "purchasesPrice",
                row.id._id,
                event.target.value
              )
            }
            value={row.purchasesPrice}
          ></TextField>
        </TableCell>
        <TableCell align="center">
          <TextField
            size="small"
            onChange={(event) =>
              props.handleProductInsideInvoiceChangeText(
                "purchasesDiscount",
                row.id._id,
                event.target.value
              )
            }

            onBlur={(event) =>
              props.handleProductInsideInvoiceChange(
                "purchasesDiscount",
                row.id._id,
                event.target.value
              )
            }
            value={row.purchasesDiscount}
          ></TextField>
        </TableCell>
        <TableCell align="center">
          <TextField
            size="small"
            onChange={(event) =>
              props.handleProductInsideInvoiceChangeText(
                "gift",
                row.id._id,
                event.target.value
              )
            }

            onBlur={(event) =>
              props.handleProductInsideInvoiceChange(
                "gift",
                row.id._id,
                event.target.value
              )
            }
            value={row.gift}
          ></TextField>
        </TableCell>
        <TableCell align="center">
          <Select
                      onChange={(event) =>
                        props.handleProductInsideInvoiceChangeText(
                          "color",
                          row.id._id,
                          event.target.value
                        )
                      }
          
            onBlur={(event) =>
              props.handleProductInsideInvoiceChange(
                "color",
                row.id._id,
                event.target.value
              )
            }
            value={row.color}
          >
            <MenuItem value="" disabled>
              اللون المطلوب
            </MenuItem>
            {row.id.color.map((province) => (
              <MenuItem key={province._id} value={province._id}>
                {province.name}
              </MenuItem>
            ))}
          </Select>
        </TableCell>
        <TableCell align="center">
          <TextField
            size="small"
            onChange={(event) =>
              props.handleProductInsideInvoiceChangeText(
                "comment",
                row.id._id,
                event.target.value
              )
            }

            onBlur={(event) =>
              props.handleProductInsideInvoiceChange(
                "comment",
                row.id._id,
                event.target.value
              )
            }
            value={row.comment}
          ></TextField>
        </TableCell>
        <TableCell align="center">
          <TableCell align="center">
            {row.quantity * row.purchasesPrice - row.purchasesDiscount}
          </TableCell>
        </TableCell>
        <TableCell align="center">
          <IconButton
            onClick={() => {
              props.handleProductRemove(row._id);
            }}
          >
            <DeleteOutlineIcon className=" text-red-500"></DeleteOutlineIcon>
          </IconButton>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function PurchasesNewList(props) {
  return (
    <TableContainer component={Paper}>
      <Table
        aria-label="collapsible table"
        sx={{ minWidth: 650 }}
        size="small"
        style={{ direction: "rtl" }}
      >
        <TableHead>
          <TableRow>
            <TableCell>اسم المنتج</TableCell>
            <TableCell align="center">الكمية</TableCell>
            <TableCell align="center">سعر الوحدة</TableCell>
            <TableCell align="center">الخصم</TableCell>
            <TableCell align="center">عدد الهدايا</TableCell>
            <TableCell align="center">اللون</TableCell>
            <TableCell align="center">ملاحضات</TableCell>
            <TableCell align="center">اجمالي التكلفة</TableCell>
            <TableCell align="center">الخيارات</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.purchasesInvoice.product?.map((row) => (
            <Row
            handleProductInsideInvoiceChangeText ={props.handleProductInsideInvoiceChangeText }
              key={row._id}
              row={row}
              handleProductRemove={props.handleProductRemove}
              handleProductInsideInvoiceChange={
                props.handleProductInsideInvoiceChange
              }
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
